import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {HttpParams} from '@angular/common/http';
import {catchError, first, map, retry} from 'rxjs/operators';
import {PluginLocalStorageService} from '../plugin-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CanEditTranspositionGuard implements CanActivate, CanActivateChild {

  constructor(private workflowService: WorkflowService, private router: Router, private localStorage: PluginLocalStorageService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeUrl = route.url.toString();
    if (routeUrl.includes('credentials') && route.queryParams.failed_credential_id?.startsWith('sql-')) {
      return of(true);
    }

    // Checking if client is allowed to access transpositions page
    // All pages check to enable the navigation button
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'transposition_categories') };
    return this.workflowService.execute('lookup', options, 'ams/', 'GET')
      .pipe(first(), retry(3), map(response => {
        const can_edit = response.output.can_edit;
        this.localStorage.saveTranspositionData({can_edit, categories: response.output.items});

        if (routeUrl.includes('transpositions') && !can_edit) {
          this.router.navigate(['local/about']);
          return false;
        }
        return true;
      }), catchError(() => {
        return of(!routeUrl.includes('transpositions')); // Allowing access for not-transposition pages
      }));
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route);
  }
}
