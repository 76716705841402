import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DmConnectPackageOptions} from '../dm-connect-package-options';
import {SqlConfiguration} from '../sql-configuration';
import {OnPackageOptionChange} from './on-package-option-change';
import {SqlTranspositions} from '../sql-transpositions';


@Component({
  selector: 'app-sql-transpositions-selector',
  template: `
    <div *ngIf="this.src_object" class="card d-block">
      <div class="card-body">
        <div class="col-12" id="tabJSON">
          <h5 class="card-title mb-3">SQL Transpositions Libraries</h5>
          <div class="row">
            <div class="col-6">
              <div class="form-floating">
                <select class="form-select" aria-label="Select Default Library" [(ngModel)]="this.src_object.default_library" (change)="onTranspositionChange()">
                  <option *ngFor="let item of this.src_object.libraries" [ngValue]="item">{{ item }}</option>
                </select>
                <label>Select the Default Library</label>
              </div>
            </div>

            <div class="text-sm-end col-md-6">
              <button type="button" class="btn btn-success mb-2 mr-2" data-bs-toggle="modal" data-bs-target="#transposition_modal"
                      (click)="this.onOpenModal(null)">
                <i class="mdi mdi-plus mr-1"></i> Add
              </button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <div class="form-floating">
                <select class="form-select" aria-label="Select SQL Connection" [(ngModel)]="this.src_object.connection_string" (change)="onTranspositionChange()">
                  <option *ngFor="let item of this.sql_configurations" value="{{item.key}}">{{ item.key }}</option>
                </select>
                <label>Select the SQL Connection</label>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-centered mb-0">
              <thead class="thead-light">
              <tr>
                <th>Library Name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.src_object.libraries">
                <td>{{ item }}</td>
                <td>
                  <a (click)="this.onOpenModal(item)" data-bs-toggle="modal" data-bs-target="#transposition_modal" class="action-icon"><i class="mdi mdi-square-edit-outline"></i></a>
                  <a (click)="this.onRemoveLibrary(item)" class="action-icon"><i class="mdi mdi-delete"></i></a>
                  <a [routerLink]="['../transposition-editor']" [queryParams]="{library_name: item}" class="action-icon"><i class="uil-books"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> <!-- end card-body-->
    </div> <!-- end card-->

    <div id="transposition_modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-3 px-4 border-bottom-0">
            <h4 class="modal-title">Configure Library</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div *ngIf="this.modal_exception" class="alert alert-danger" role="alert">
            <p class="mb-0">{{ this.modal_exception }}</p>
          </div>

          <form class="ps-3 pe-3">
            <div class="modal-body">
              <div class="row g-2">
                <label class="form-label" for="library_name">Name</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="library_name" name="library_name"
                         [(ngModel)]="this.modal_library.name" (input)="this.validateModal($any($event.target).value)">
                </div>
              </div>
            </div>
            <div class="modal-footer d-block">
              <div class="row g-2 text-end">
                <div class="mb-3 col-12">
                  <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!this.modal_can_submit" (click)="this.onSaveLibrary(this.modal_library)">
                    {{ this.modal_mode == ModalMode.Add ? 'Add' : 'Update' }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div> <!-- end modal-content -->
      </div>
    </div> <!-- end modal -->
  `,
  styles: [``]
})

export class SqlTranspositionConfiguratorComponent implements OnInit , OnPackageOptionChange
{
  readonly ModalMode = ModalMode;
  @Input() packageOptions: Subject<DmConnectPackageOptions>;
  @Output() onChange = new EventEmitter<any>();
  public sql_configurations = new Array<SqlConfiguration>();
  public src_object: SqlTranspositions;
  public modal_can_submit = false;
  public modal_exception: string;
  public modal_library = new ModalLibrary();
  public modal_mode: ModalMode;

  ngOnInit(): void
  {
    this.packageOptions.subscribe((x) => {
      this.sql_configurations = x.sql_configuration;
      this.src_object = x.sql_transpositions;
    });
  }

  onOpenModal(library: string): void
  {
    this.modal_exception = null;
    this.modal_mode = library == null ? ModalMode.Add : ModalMode.Update;
    this.modal_library.id = library;
    this.modal_library.name = library;
    this.validateModal(library);
  }

  onRemoveLibrary(library: string): void
  {
    this.src_object.libraries.splice(this.src_object.libraries.indexOf(library), 1);
    if (this.src_object.default_library === library) {
      this.src_object.default_library = library;
      this.onTranspositionChange();
    }
  }

  onSaveLibrary(value: ModalLibrary): void
  {
    // Adding new library
    if (value.id == null) {
      this.src_object.libraries.push(value.name);
      if (this.src_object.libraries.length === 1) {
        this.src_object.default_library = value.name;
      }
    }
    // Updating existing library
    else {
      const index = this.src_object.libraries.indexOf(value.id);
      this.src_object.libraries[index] = value.name;
    }

    this.onTranspositionChange();
  }

  onTranspositionChange(): void
  {
    this.onChange.emit({ sql_transpositions: this.src_object });
  }

  validateModal(library: string): void
  {
    this.modal_can_submit = library && !this.src_object.libraries.includes(library);
  }
}


class ModalLibrary
{
  public id: string;
  public name: string;
}

enum ModalMode
{
  Add, Update
}
