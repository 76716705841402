import {Component, OnInit} from '@angular/core';
import {Observable, of, throwError, TimeoutError} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {catchError, first, map, retry, timeout} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Batch Processor</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <ng-container>
        <div aria-live="polite" aria-atomic="true" role="alert" class="position-relative z-1">
          <div class="px-2 mb-2 position-absolute end-0">
            <ng-container *ngFor="let alert of alerts; let i = index">
              <ng-container [ngSwitch]="alert.type">
                <ng-container *ngSwitchCase="'files_success'">
                  <div class="alert alert-success alert-dismissible text-bg-success border-0 fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="alerts.splice(i, 1)"></button>
                    <h4>The data from this import has been placed in the following AMS records.</h4>
                    <p>Tip: You can click on the desired record to copy the value into your clipboard.</p>
                    <hr>
                    <ul>
                      <li *ngFor="let id of alert.submission_ids" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" title="Copy to clipboard" (click)="copy_id(id)"><i class="uil uil-copy"></i> {{ id }}</li>
                    </ul>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'files_error'">
                  <div class="alert alert-danger alert-dismissible text-bg-danger border-0 fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="alerts.splice(i, 1)"></button>
                    <h4 class="me-auto">{{alert.title}}</h4>
                    <hr>
                    <p class="my-2">{{alert.message}}</p>
                    <p class="mb-0">If the error persists please contact us at <a class="text-decoration-underline" href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <div class="card card-fixed d-block">
          <div class="card-body">
            <div class="row h-100 d-inline-flex justify-content-between w-100">
              <div class="col-3 d-inline-flex align-items-center">
                <h5 class="card-title">Batch Select</h5>
              </div>
              <div class="col-auto text-end">
                <button class="btn btn-primary mb-2" (click)="google_analytics.event('selected_batch', 'batch-processor', 'Selected Batch.')" [disabled]="selected_paths.length == 0 || status == LoadingStatus.WAITING_FOR_RESPONSE">
                    <span [hidden]="status != LoadingStatus.WAITING_FOR_RESPONSE">
                      <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Loading...
                    </span>
                  <span [hidden]="status == LoadingStatus.WAITING_FOR_RESPONSE" (click)="triggerFileProcessing()">Import</span>
                </button>
              </div>
            </div>
            <form>
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let option of file_options | async">
                  <input type="checkbox" class="form-check-input me-5" (click)="addOrRemoveFromArray(selected_paths, option.path)" id="{{option.name}}" [checked]="selected_paths.indexOf(option.path) !== -1">
                  <label class="form-check-label fw-normal list-group-item-label w-75" for="{{option.name}}">{{option.name}}</label>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="(failed_batches | async)?.length || status == LoadingStatus.RESPONSE_RECEIVED">
      <ng-container>
        <div aria-live="polite" aria-atomic="true" role="alert" class="position-relative z-1">
          <div class="px-2 mb-2 position-absolute end-0">
            <ng-container *ngFor="let alert of alerts; let i = index">
              <ng-container [ngSwitch]="alert.type">
                <ng-container *ngSwitchCase="'batches_success'">
                  <div class="alert alert-success alert-dismissible text-bg-success border-0 fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="alerts.splice(i, 1)"></button>
                    <h4>The data from this import has been placed in the following AMS records.</h4>
                    <p>Tip: You can click on the desired record to copy the value into your clipboard.</p>
                    <hr>
                    <ul>
                      <li *ngFor="let id of alert.submission_ids" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" title="Copy to clipboard" (click)="copy_id(id)"><i class="uil uil-copy"></i> {{ id }}</li>
                    </ul>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'batches_error'">
                  <div class="alert alert-danger alert-dismissible text-bg-danger border-0 fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="alerts.splice(i, 1)"></button>
                    <h4 class="me-auto">{{alert.title}}</h4>
                    <hr>
                    <p class="my-2">{{alert.message}}</p>
                    <p class="mb-0">If the error persists please contact us at <a class="text-decoration-underline" href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <div class="card card-fixed d-block">
          <div class="card-body">
            <div class="row h-100 d-inline-flex justify-content-between w-100">
              <div class="col-3 d-inline-flex align-items-center">
                  <h5 class="card-title">Unprocessed Batches</h5>
              </div>
              <div class="col-auto text-end">
                <button class="btn btn-primary mb-2" (click)="google_analytics.event('selected_failed_batch', 'batch-processor', 'Selected Failed Batch.')" [disabled]="selected_batches.length == 0 || status == LoadingStatus.WAITING_FOR_RESPONSE">
                  <span [hidden]="status != LoadingStatus.WAITING_FOR_RESPONSE">
                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Loading...
                  </span>
                  <span [hidden]="status == LoadingStatus.WAITING_FOR_RESPONSE" (click)="triggerBatchProcessing()">Process</span>
                </button>
              </div>
            </div>
            <form>
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let option of failed_batches | async">
                  <input type="checkbox" class="form-check-input me-5" (click)="addOrRemoveFromArray(selected_batches, option.name)" id="{{option.name}}" [checked]="selected_batches.indexOf(option.name) !== -1" value="option.name">
                  <label class="form-check-label fw-normal list-group-item-label w-75" for="{{option.name}}">{{option.name}}</label>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .z-1 {
      z-index: 100;
    }
    ul {
      list-style-type: none;
    }
    li:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    .alert {
      max-width: 33.33vw;
    }
    .list-group-item-label {
      color: #6c757d
    }
  `]
})

export class BatchProcessorComponent implements OnInit
{
  public readonly support_email = 'Support@DarkMatterIns.com';
  public file_options: Observable<File[]>;
  public failed_batches: Observable<Batch[]>;
  public selected_paths: string[] = [];
  public selected_batches: string[] = [];
  public status: LoadingStatus;
  public alerts: {submission_ids: string[], type: string, message: string, title: string}[] = [];
  public LoadingStatus = LoadingStatus;
  private workflow_name: string;

  constructor(private workflow_service: WorkflowService, public google_analytics: GoogleAnalyticsService, private route: ActivatedRoute) {}

  ngOnInit(): void
  {
    this.file_options = this.callLookupWorkflow('directory', 'files');
    this.failed_batches = this.callLookupWorkflow('failed_batch', 'batches');
  }

  callLookupWorkflow(lookup_type: string, execution_type: string): Observable<any[]>
  {
    return this.workflow_service.execute('lookup', { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', lookup_type) }, 'ams/', 'GET').pipe(first(),
      map(r =>
      {
        this.status = LoadingStatus.RESPONSE_RECEIVED;
        const response = r.output?.items;

        if (response && response.length > 0) {
          response.sort((a, b) => Date.parse(b.modified_at) - Date.parse(a.modified_at));

          if (this.workflow_name === undefined) {
            this.workflow_name = response[0]?.workflow_name;
          }
          return response;
        }
      }),
      catchError(err => {
        this.status = LoadingStatus.RESPONSE_RECEIVED;
        if (err instanceof TimeoutError) {
          this.addToast(`${execution_type}_error`, 'Request timed out.', `Error while retrieving ${execution_type}.`);
        }
        return throwError(err);
      }),
    );
  }

  triggerFileProcessing(): void {
    this.status = LoadingStatus.WAITING_FOR_RESPONSE;
    this.google_analytics.event('clicked_import', 'batch-processor', 'Clicked Import');
    this.createStagingData(this.route.snapshot.paramMap.get('workflow_name'), {headers: {'Cache-Control': 'no-cache'}, file_paths: this.selected_paths}, 'files');
  }

  triggerBatchProcessing(): void {
    this.status = LoadingStatus.WAITING_FOR_RESPONSE;
    this.google_analytics.event('clicked_process', 'batch-processor', 'Clicked Process');
    this.createStagingData(this.workflow_name, { headers: {'Cache-Control': 'no-cache'}, batch_name: this.selected_batches}, 'batches');
  }

  addOrRemoveFromArray(array: string[], value: string): void {
    const index = array.findIndex(m => m === value);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }
  }

  copy_id(id: string): void{
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (id));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  private addToast(type: string, message: string, title: string, submission_ids: string[] = []): void
  {
    this.alerts.push({type, message, title, submission_ids});
  }

  private createStagingData(workflow_name: string, param: any, toast_type: string): void {
    this.alerts = [];
    this.workflow_service.execute(workflow_name, param, 'workflow/', 'POST').pipe(first(),
      map(r =>
      {
        this.status = LoadingStatus.RESPONSE_RECEIVED;
        if (r && r.success) {
          this.addToast(`${toast_type}_success`, '', '', Object.keys(r.output.success));
          return of(true);
        } else {
          this.addToast(`${toast_type}_error`, r.output.error.message, `Workflow Id: ${r.output.error.workflow_id}`);
        }
      }),
      catchError(err => {
        this.status = LoadingStatus.RESPONSE_RECEIVED;
        if (err instanceof TimeoutError) {
          this.addToast(`${toast_type}_error`, 'Request timed out.', 'Error while importing batch file.');
        }
        return throwError(err);
      }),
    ).subscribe(() => {
        this.file_options = this.callLookupWorkflow('directory', 'files');
        this.failed_batches = this.callLookupWorkflow('failed_batch', 'batches');
        this.selected_paths = [];
        this.selected_batches = [];
    });
  }
}

class File
{
  name: string;
  path: string;
}

class Batch
{
  name: string;
  workflow_name: string;
}

enum LoadingStatus
{
  RESPONSE_RECEIVED,
  WAITING_FOR_RESPONSE
}
