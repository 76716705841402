import {Component, OnInit} from '@angular/core';
import {first, map, retry} from 'rxjs/operators';
import {WorkflowService} from '../workflows/workflow-service.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Retrieve Workflow</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 align-content-center mb-md-0 mb-2">
                <div *ngIf="error_message" class="alert alert-danger mb-0" role="alert">
                  <p class="mb-0">{{error_message}}</p>
                  <ng-container *ngIf="error_id">
                    <hr>
                    <p class="mb-0">Workflow Id: {{error_id}}</p>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-6 align-content-center">
                <div class="input-group">
                  <input type="text" id="workflow-id" class="form-control" name="workflow-id" [(ngModel)]="workflow_id" placeholder="da6d0f79-fb15-407b-8cd4-76e7df59e93e">
                  <button type="submit" class="btn btn-primary" (click)="getWorkflowLogs()" [disabled]="is_loading">
                    <ngb-panel *ngIf="is_loading">
                      <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                      Searching...
                    </ngb-panel>
                    <ngb-panel *ngIf="!is_loading">
                      Search
                    </ngb-panel>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card widget-flat">
          <div class="card-body">
            <h4 class="header-title mb-2">Workflow Activity Audits</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped position-relative">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">Map Name</th>
                    <th scope="col">Previous Fact</th>
                    <th scope="col">This Fact</th>
                    <th scope="col">Exception</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="!is_loading && workflow_audits && workflow_audits.length > 0">
                    <tr *ngFor="let row of workflow_audits">
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.MapName}}</div></td>
                      <td class="align-middle mxw-20vw"><pre class="mxh-50vh">{{JSON.stringify(JSON.parse(row.PreviousFact), undefined, 1)}}</pre></td>
                      <td class="align-middle mxw-20vw"><pre class="mxh-50vh">{{JSON.stringify(JSON.parse(row.ThisFact), undefined, 1)}}</pre></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.Exception}}</div></td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="!is_loading && (!workflow_audits || workflow_audits.length == 0)">
                    <tr>
                      <td [colSpan]="4">No records found. Try a different workflow id.</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="is_loading">
                    <tr>
                      <td [colSpan]="4">Searching for workflow logs...</td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card widget-flat">
          <div class="card-body">
            <h4 class="header-title">API Interactions</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped position-relative">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">Uri</th>
                    <th scope="col">Method</th>
                    <th scope="col">Request Content</th>
                    <th scope="col">Response Status</th>
                    <th scope="col">Reason Phrase</th>
                    <th scope="col">Response Content</th>
                    <th scope="col">Exception</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="!is_loading && api_interactions && api_interactions.length > 0">
                    <tr *ngFor="let row of api_interactions">
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.Uri}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.Method}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.RequestContent}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.ResponseStatus}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.ReasonPhrase}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.ResponseContent}}</div></td>
                      <td class="align-middle mxw-20vw"><div class="mxh-50vh">{{row.Exception}}</div></td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="!is_loading && (!api_interactions || api_interactions.length == 0)">
                    <tr>
                      <td [colSpan]="7">No records found. Try a different workflow id.</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="is_loading">
                    <tr>
                      <td [colSpan]="7">Searching for api interactions...</td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    td, th {
      padding: .5em;
      vertical-align: center;
    }
    .mxw-20vw {
      max-width: 20vw;
    }
    .mxh-50vh {
      max-height: 50vh !important;
      overflow-x: hidden !important;
    }
  `]
})
export class RetrieveWorkflowComponent implements OnInit {
  public workflow_id: string;
  public is_loading = false;
  public error_message = null;
  public error_id = null;
  public workflow_audits: any[];
  public api_interactions: any[];
  protected readonly JSON = JSON;

  constructor(private workflowService: WorkflowService) {}

  ngOnInit(): void {}

  public getWorkflowLogs(): void {
    this.is_loading = true;
    this.workflowService.execute('workflow_audits', {workflow_id: this.workflow_id})
      .pipe(first(), retry(3), map((r) => {
        const items = r?.output?.success;
        if (r?.success && items) {
          this.workflow_audits = items.workflow_audits;
          this.api_interactions = items.api_interactions;
          this.error_message = null;
          this.error_id = null;
        } else {
          const error = r.output?.error;
          this.error_message = error?.message;
          this.error_id = error?.workflow_id;
          this.workflow_audits = [];
          this.api_interactions = [];
        }
      })).subscribe(() => this.is_loading = false);
  }
}
