import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {HttpParams} from '@angular/common/http';
import {map, retry} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {WorkflowService} from '../workflows/workflow-service.service';
import {WorkflowResult} from '../dm-connect-api.service';

@Component({
  selector: 'app-aim-screens',
  template: `
    <div class="container">
      <app-loading-indicator loading_text="Checking for assigned interfaces..." [is_loading]="this.is_loading"></app-loading-indicator>
      <ng-template [ngIf]="!this.has_interfaces && !this.is_loading">
        <div class="alert alert-danger" role="alert">
          <h4 class="alert-heading">There are no workflow Interfaces assigned to this account.</h4>
          <p>Please reach out to your admin to get workflows assigned to your account.</p>
        </div>
      </ng-template>
      <div class="row">
        <ng-container *ngFor="let value of interface_list | async">
          <div class="col-lg-3">
            <div class="card shadow-none hover-card" (click)="onClickInterface(value)" [ngClass]="{'disabled-card' : this.is_disabled }">
              <div class="disabled-card-overlay">&nbsp;</div>
              <div class="grid-container">
                <div class="card-img-top p-2">
                  <img class="mx-auto w-75 d-block" src="{{value.image}}" alt="{{value.description}}">
                </div>
                <div class="card-body p-2 text-center">
                  <a class="btn stretched-link">
                    <h5 class="card-title">{{value.description}}</h5>
                  </a>
                </div>
                <div class="card-footer bg-primary text-light text-center">
                  <span *ngIf="value.is_working" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                  {{ value.is_working ? 'In Progress...' : value.title }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .card-title {
        height: 2em;
      }

      .card-img-top {
        height: 127px;
      }

      .card-img-top img {
        max-width: 165px;
        max-height: 123px;
      }

      .hover-card {
        border: 1px solid #eee;
      }

      .hover-card:hover {
        -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075) !important;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075) !important;
        transform: scale(1.05);
      }

      .disabled-card .disabled-card-overlay {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0.25rem;
       }
    `
  ]
})
export class AmsLandingComponent implements OnInit {
  public is_loading = true;
  public interface_list: Observable<Interface[]>;
  public is_disabled = false;
  public has_interfaces: boolean;
  constructor(private router: Router, private route: ActivatedRoute, private googleAnalytics: GoogleAnalyticsService, private workflowService: WorkflowService) {}

  ngOnInit(): void {
    this.interface_list = this.getLookupData('available_interfaces').pipe(map(r => {
      const response = r.output.items;
      this.is_loading = false;
      this.has_interfaces = !!response.length;

      if (response.length > 1) {
        return response;
      } else {
        this.handleInterfaceRequest(response[0]);
      }

      return false;
    }));
  }

  public getLookupData(lookupType: string): Observable<WorkflowResult> {
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', lookupType) };

    return this.workflowService.execute('lookup', options, 'ams/', 'GET').pipe(retry(3));
  }

  onClickInterface(value: Interface): void {
    if (this.is_disabled) {
      return;
    }

    this.googleAnalytics.event('clicked_interface_' + value.id, 'aim_interface', 'Clicked AIM interface');
    this.handleInterfaceRequest(value);
  }

  private handleInterfaceRequest(value: Interface): void {
    if (value) {
      value.is_working = true;
      this.is_disabled = true;
      const output = {...this.route.snapshot.queryParams, dm_portal_id: value.id};

      if (value.redirect_url) {
        this.router.navigate([value.redirect_url], {queryParams: output});
        return;
      }

      this.workflowService.execute(value.workflow_id, {...output}).subscribe((r) => {
        value.is_working = false;
        this.is_disabled = false;
      });
    }
  }
}

class Interface
{
  public id: string;
  public workflow_id: string;
  public redirect_url: string;
  public image: string;
  public title: string;
  public description: string;
  public is_working: boolean | undefined;
}
