import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthModule} from '@auth0/auth0-angular';
import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {AboutComponent} from './local/about.component';
import {TranspositionTopicsComponent} from './local/transposition_topics.component';
import {LocalCredentialsComponent} from './local/local-credentials.component';
import {DiagnosticsComponent} from './local/diagnostics.component';
import {DashboardComponent} from './local/analytics/dashboard.component';
import {CorrelationActivityComponent} from './local/analytics/components/correlation-activity.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AimClarionDoorComponent} from './aim/aim-clarion-door.component';
import {InitializingComponent} from './initializing/initializing.component';
import {DmbridgeAuthorizeComponent} from './dmbridge/dmbridge-authorize.component';
import {TsgaAimLandingComponent} from './aim/tsga-aim-landing.component';
import {RoadblockComponent} from './portals/roadblock.component';
import {PluginComponent} from './portals/plugin.component';
import {PreflightCheckComponent} from './portals/preflight-check.component';
import {ClearanceComponent} from './portals/clearance.component';
import {NewSubmissionComponent} from './portals/new-submission.component';
import {SuccessComponent} from './portals/success.component';
import {LocalMasterComponent} from './local/local-master/local-master.component';
import {DmBridgeMasterComponent} from './dmbridge/dmbridge-master/dmbridge-master.component';
import {AimMasterComponent} from './aim/aim-master/aim-master.component';
import {AimSuccessComponent} from './aim/aim-success.component';
import {ConfigMasterComponent} from './config/config-master/config-master.component';
import {ConfigHomeComponent} from './config/config-home.component';
import {UserManagementComponent} from './config/user-management.component';
import {DataLibraryComponent} from './config/data-library.component';
import {ConnectorsConfigurationComponent} from './config/connectors-configuration.component';
import {InterfacesConfigurationComponent} from './config/interfaces-configuration.component';
import {WorkflowsConfigurationComponent} from './config/workflows-configuration.component';
import {CreateWorkflowComponent} from './config/create-workflow.component';
import {StagingBucketListComponent} from './config/staging-bucket-list.component';
import {CreateStagingBucketComponent} from './config/create-staging-bucket.component';
import {SLTComponent} from './config/slt.component';
import {ErrorComponent} from './config/error.component';
import {HttpAuthInterceptor} from './security/HttpAuthInterceptor';
import {HazardhubAimLandingComponent} from './aim/hazardhub-aim-landing.component';
import {LoadingIndicatorComponent} from './loading-indicator.component';
import {SelectSubmissionComponent} from './aim/select-submission.component';
import {DmbridgeAimLandingComponent} from './aim/dmbridge-aim-landing.component';
import {AmsLandingComponent} from './aim/ams-landing.component';
import {ApplicationInterfaceDetailsComponent} from './config/application-interface-details.component';
import {FunctionDetailsComponent} from './config/function-details.component';
import {TranspositionMappingsComponent} from './config/transposition-mappings.component';
import {IdRoadblockComponent} from './portals/id-roadblock.component';
import {InterfaceCostingComponent} from './config/interface-costing.component';
import {InterfaceAssignmentComponent} from './config/interface-assignment.component';
import {LicensingComponent} from './support-control/licensing.component';
import {SupportControlMasterComponent} from './support-control/support-control-master/support-control-master.component';
import {GenerateConfigurationComponent} from './support-control/generate-configuration.component';
import {AmsSelectorComponent} from './support-control/packaging/ams-selector';
import {TestLauncherComponent} from './support-control/test-launcher/test-launcher.component';
import {FileUploaderComponent} from './support-control/packaging/file-uploader';
import {GatewayConfigComponent} from './support-control/packaging/gateway-config';
import {ApplicationInterfaceSelectorComponent} from './support-control/packaging/application-interface-selector';
import {PythonSqlConfigComponent} from './support-control/packaging/python-sql-config';
import {NexsureConfigComponent} from './support-control/packaging/nexsure-config';
import {AimConfigComponent} from './support-control/packaging/aim-config';
import {GeneralSettingsComponent} from './support-control/packaging/general-settings';
import {ConceptOneConfigComponent} from './support-control/packaging/concept-one-config';
import {TsgaInvoiceBridgeConfigComponent} from './support-control/packaging/tsga-invoice-bridge-config';
import {ClariondoorConfigComponent} from './support-control/packaging/clariondoor-config';
import {InputOnePbsConfigComponent} from './support-control/packaging/input-one-pbs-config';
import {SqlTranspositionConfiguratorComponent} from './support-control/packaging/sql-transposition-configurator';
import {JsonTranspositionConfiguratorComponent} from './support-control/packaging/json-transposition-configurator';
import {SqlConnectionConfiguratorComponent} from './support-control/packaging/sql-connection-configurator.component';
import {ExtensionLauncherComponent} from './support-control/test-launcher/extension-launcher.component';
import {TranspositionEditorComponent} from './support-control/packaging/transpositions/transposition-editor';
import {CorsConfiguratorComponent} from './support-control/packaging/cors-configurator';
import {ResetConfigComponent} from './support-control/packaging/reset-config';
import { TestCorrelationComponent } from './local/diagnostics/test-correlation.component';
import {RetrieveWorkflowComponent} from './local/retrieve-workflow.component';
import {LoadingShimmerComponent} from './loading-shimmer.component';
import {FtpPlProcessorComponent} from './local/ftp-pl-processor.component';
import {BatchProcessorComponent} from './local/batch-processor.component';
import {SubmissionTotalsComponent} from './local/analytics/components/submission-totals.component';
import {AccountExecutiveComponent} from './local/analytics/components/account-executive.component';
import {AggregateInterfaceComponent} from './local/analytics/components/aggregate-interface.component';
import {BoundInterfaceComponent} from './local/analytics/components/bound_interface.component';
import {UnboundByInterfaceComponent} from './local/analytics/components/unbound_by_interface.component';
import {MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from './auth-config';
import {CustomerComponent} from './support-control/customer.component';

@NgModule({
  declarations: [
    AimSuccessComponent,
    AboutComponent,
    AppComponent,
    LocalCredentialsComponent,
    DiagnosticsComponent,
    FtpPlProcessorComponent,
    BatchProcessorComponent,
    DashboardComponent,
    AggregateInterfaceComponent,
    CorrelationActivityComponent,
    SubmissionTotalsComponent,
    UnboundByInterfaceComponent,
    AccountExecutiveComponent,
    BoundInterfaceComponent,
    AimClarionDoorComponent,
    InitializingComponent,
    DmbridgeAuthorizeComponent,
    TsgaAimLandingComponent,
    HazardhubAimLandingComponent,
    UserManagementComponent,
    RoadblockComponent,
    SuccessComponent,
    PluginComponent,
    PreflightCheckComponent,
    ClearanceComponent,
    NewSubmissionComponent,
    LocalMasterComponent,
    DmBridgeMasterComponent,
    AimMasterComponent,
    ConfigMasterComponent,
    ConfigHomeComponent,
    DataLibraryComponent,
    ConnectorsConfigurationComponent,
    InterfacesConfigurationComponent,
    WorkflowsConfigurationComponent,
    CreateWorkflowComponent,
    StagingBucketListComponent,
    CreateStagingBucketComponent,
    SLTComponent,
    ErrorComponent,
    LoadingIndicatorComponent,
    LoadingShimmerComponent,
    SelectSubmissionComponent,
    DmbridgeAimLandingComponent,
    AmsLandingComponent,
    TranspositionTopicsComponent,
    ApplicationInterfaceDetailsComponent,
    FunctionDetailsComponent,
    TranspositionMappingsComponent,
    IdRoadblockComponent,
    InterfaceCostingComponent,
    InterfaceAssignmentComponent,
    LicensingComponent,
    CustomerComponent,
    SupportControlMasterComponent,
    GenerateConfigurationComponent,
    AmsSelectorComponent,
    JsonTranspositionConfiguratorComponent,
    SqlTranspositionConfiguratorComponent,
    GatewayConfigComponent,
    InterfaceAssignmentComponent,
    TestLauncherComponent,
    ExtensionLauncherComponent,
    FileUploaderComponent,
    ApplicationInterfaceSelectorComponent,
    PythonSqlConfigComponent,
    AimConfigComponent,
    NexsureConfigComponent,
    ConceptOneConfigComponent,
    TsgaInvoiceBridgeConfigComponent,
    GeneralSettingsComponent,
    ClariondoorConfigComponent,
    InputOnePbsConfigComponent,
    SqlConnectionConfiguratorComponent,
    TranspositionEditorComponent,
    CorsConfiguratorComponent,
    ResetConfigComponent,
    TestCorrelationComponent,
    RetrieveWorkflowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    // @ts-ignore
    NgxGoogleAnalyticsModule.forRoot(Dmc.GOOGLE_ANALYTICS, [{command: 'config', values: [Dmc.GOOGLE_ANALYTICS, {cookie_flags: 'secure;samesite=none'}]}]),
    FontAwesomeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...environment.auth,
      // TODO uncomment this when an endpoint has been made for the user management screen and update values accordingly
      // httpInterceptor: {
      //   allowedList: [ {
      //     uri: 'http://127.0.0.1:8080/dmconnect/',
      //     tokenOptions: {
      //       audience: 'https://dmbridge.local/api/v2/',
      //       scope: 'read:current_user'
      //     }
      //   }],
      // },
    }),
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map()
      })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
