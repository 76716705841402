import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DmconnectBrowserService} from '../dmconnect-browser.service';

@Component({
  selector: 'app-success',
  template: `
    <div class="alert alert-success" role="alert">
      <div [ngSwitch]="workflow_name">
        <p *ngSwitchCase="'outbound'">The submission has been successfully sent. This window will close automatically.</p>
        <p *ngSwitchCase="'input1'">The file has been successfully created. Refresh the database connections to make the most recent versions visible. This window will close automatically.</p>
        <p *ngSwitchDefault>The indication has been successfully imported. Refresh the database connections to make the most recent versions visible. This window will close automatically.</p>
      </div>
    </div>`,
  styles: [``]
})
export class AimSuccessComponent implements OnInit {
  public workflow_name;

  constructor(private route: ActivatedRoute, private browserService: DmconnectBrowserService) {
  }

  ngOnInit(): void {
    const routeData = this.route.snapshot;
    this.workflow_name = routeData.queryParamMap.get('workflow_name')?.toLowerCase();
    setTimeout(() => { this.browserService.closeWindow(); }, 10000);
  }
}
